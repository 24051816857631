<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        @if (message.icon) {
          <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
        }
        <h4>{{message.title}}</h4>
        @if (!message.whatsapp) {
          <p>{{message.message}}</p>
        }
        @if (message.whatsapp) {
          <p class="message">
            Ups! Tuvimos un problema con tu pago. Hace <span (click)="goWhatsapp()">click acá</span> para solucionarlo por Whatsapp
          </p>
        }
      </div>
      @if (message.yesno) {
        <div class="grid p-fluid">
          <div class="col-6">
            <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
          </div>
          <div class="col-6">
            <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
          </div>
        </div>
      }
    </div>
  </ng-template>
</p-toast>