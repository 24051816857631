import { ChangeDetectorRef, Component, NgZone, OnInit, Renderer2, inject } from '@angular/core';
import { MessageService, SharedModule } from 'primeng/api';
import { Toast, ToastModule } from 'primeng/toast';
import { ChoizMessagesService, IChoizMessage } from './choiz-messages.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';


@Component({
    selector: 'choiz-messages',
    templateUrl: './choiz-messages.component.html',
    styleUrls: ['./choiz-messages.component.scss'],
    providers: [DialogService, MessageService],
    standalone: true,
    imports: [CommonModule, ToastModule, SharedModule, ButtonModule, ChoizMessagesComponent, DialogModule]
})
export class ChoizMessagesComponent implements OnInit {

  private messageService = inject(MessageService); 
  private cms = inject(ChoizMessagesService);
  private cd = inject(ChangeDetectorRef);
  private render = inject(Renderer2);

  message: IChoizMessage;

  ngOnInit(): void {
    this.cms._showMessage.subscribe((change: IChoizMessage) => {
      this.message = change;
      setTimeout(() => {
        this.showMessage(this.message);    
      }, 100);
    });  
  }

  showMessage(message: IChoizMessage){
    setTimeout(() => {
      this.messageService.clear();
      this.messageService.add({key: 'c', sticky: message.autoClose ? false : true, severity: message.type, summary: message.title, detail: message.message, life: message.life});
    }, 100)
  }

  onReject(){
    if(this.message.whatsapp){
      window.location.reload();
    }
  }

  onConfirm(){}

  goWhatsapp(){
    window.location.href = 'https://api.whatsapp.com/send?phone=5491120402909'
  }

}
