import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from '@orders/orders.service';
import { ChoizMessagesService } from '@shared/components/choiz-messages/choiz-messages.service';
import { AuthService } from '@shared/auth/services/auth.service';
import { Subject, Subscription } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuxiliaresService } from '@core/services/auxiliares.service';
import { environment } from '@environments/environment';
import { xImageMyAccount } from '@core/interceptors/auth.interceptor';
import { ClientsService } from '@clients/clients.service';

@Injectable({
  providedIn: 'root'
})
export class OrdersDetailService {

  private http = inject(HttpClient);
  private fb = inject(FormBuilder);
  private _auth = inject(AuthService);
  private _orders = inject(OrdersService);
  private _cms = inject(ChoizMessagesService);
  private _auxiliares = inject(AuxiliaresService);
  private _clients = inject(ClientsService);

  loadedData: boolean = false;

  selectedOrder = signal<any>(undefined);

  comboName: string = null;
  public salePrice: string = null;
  public costPrice: string = null;
  frequency: string = null;
  isEdit: boolean = false;

  public cards: any = [];
  public details: any = [];
  public form: FormGroup = new FormGroup({});
  public formOrderMedicines: FormGroup = new FormGroup({});
  public prospectId: string = '';

  private subscriptions: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();


  get orderMedicinesFormArr(): FormArray {
    return this.form.get('order').get('orderMedicine') as FormArray;
  }

  get orderProductsFormArr(): FormArray {
    return this.form.get('order').get('orderProducts') as FormArray;
  }

  get orderMedicinesFormulasFormArr(): FormArray {
    return this.orderMedicinesFormArr.get('formulas') as FormArray;
  }

  getData(){
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getJsonDefinition(),
      ]).then(resp => {
        resolve(resp)
      }).catch(e => reject(e))
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  caracteresProhibidosValidator() {
    return (control) => {
      const valor = control.value;
      if (/[\$!"#%&\/=?+*-]/.test(valor)) {
        return { caracteresProhibidos: true };
      }
      return null;
    };
  }

  async createDefinition(){
    this.generateStructure();
  }

  async getJsonDefinition() {
    return new Promise(async (resolve, reject) => {
        this.http.get(`assets/data/detailsOrders.json`).subscribe(async (defOrders: any) => {
        this.details = await defOrders.ORDERS;
        resolve(true);
      })
    })

  }

  generateStructure(){
    this.cards = this.details.cards;
    this.generateForm();
    this.getDataAux();
    this.cards.forEach((card, i) => {
      this.getOptions(card);
    })
  }

  async generateForm(){
    await this.generateFormOrders();
    await this.setDataFormOrder();
  }

  async generateFormOrders(){
    this.form = this.fb.group({
      user: this.fb.group({
        name: [ {value: '', disabled: true}, [] ],
        lastName: [ {value: '', disabled: true}, [] ],
        dni: [ {value: '', disabled: true}, [] ],
        birthSex: [ {value: '', disabled: true}, [] ],
        selfGender: [ {value: '', disabled: true}, [] ],
        birthDay: [ {value: '', disabled: true}, [] ],
        telephone: [ {value: '', disabled: true}, [] ],
        email: [ {value: '', disabled: true}, [] ],
        prospectId: [ {value: '', disabled: true}, [] ],
      }),
      order: this.fb.group({
        comboId: [ {value: '', disabled: true}, [] ],
        comboName: [ {value: '', disabled: true}, [] ],
        frequency: [ {value: '', disabled: true}, [] ],
        medicine: [ {value: '', disabled: true}, [] ],
        orderMedicine: this.fb.array([]),
        orderProducts: this.fb.array([]),
        medicineQuantity: [ {value: '', disabled: true}, [] ],
        healthInsuranceCoverage: [ {value: '', disabled: true}, [] ],
        medicinePrice: [ {value: '', disabled: true}, [] ],
        doctorName: [ {value: '', disabled: true}, [] ],
        doctorLastName: [ {value: '', disabled: true}, [] ],
        tuition: [ {value: '', disabled: true}, [] ],
        doctor: [ {value: '', disabled: true}, [] ],
        // priceTotal: [ {value: '', disabled: true}, [] ],
        salePrice: [ {value: '', disabled: true}, [] ],
        discount: [ {value: '', disabled: true}, [] ],
        paymentLink: [ {value: '', disabled: true}, [] ],
        pharmacy: [ {value: '', disabled: true}, [] ],
        treatment: [ {value: '', disabled: true}, [] ],
        created: [ {value: '', disabled: true}, [] ],
      }),
      shipping: this.fb.group({
        street: [ {value: '', disabled: true}, [Validators.required, Validators.maxLength(50), this.caracteresProhibidosValidator()] ],
        streetNumber: [ {value: '', disabled: true}, [Validators.required, ] ], //Validators.maxLength(5)
        apartment: [ {value: '', disabled: true}, [] ],
        apartmentNumber: [ {value: '', disabled: true}, [] ], //Validators.maxLength(4), this.caracteresProhibidosValidator()
        additionalInformation: [ {value: '', disabled: true}, [Validators.maxLength(30)] ],
        province: [ {value: '', disabled: true}, [Validators.required] ],
        city: [ {value: '', disabled: true}, [Validators.required] ],
        colony: [ {value: '', disabled: true}, [Validators.required] ], //Validators.minLength(2)
        zipCode: [ {value: '', disabled: true}, [Validators.required] ],
        trackingUrl: [ {value: '', disabled: true}, [] ],
        deliveryDate: [ {value: '', disabled: true}, [] ]
      }),
      comments: this.fb.group({
        commentOrder: [ {value: '', disabled: false}, [] ],
        commentChoiz: [ {value: '', disabled: false}, [] ],
      }),
    })
    this.setDataToArr();
  }

  addNewOrderMedicine() {
    this.orderMedicinesFormArr.push(
      this.fb.group({
        id: [ {value: '', disabled: true}, [] ],
        box: [ {value: '', disabled: true}, [] ],
        order: [ {value: '', disabled: true}, [] ],
        medicine: this.fb.group({
            id: [ {value: '', disabled: true}, [] ],
            brand: [ {value: '', disabled: true}, [] ],
            formulasToString: [ {value: '', disabled: true}, [] ],
            formula: [ {value: '', disabled: true}, [] ],
            formulas: this.fb.array([]),
            laboratory: [ {value: '', disabled: true}, [] ],
            modified: [ {value: '', disabled: true}, [] ],
            presentation: [ {value: '', disabled: true}, [] ],
            price: [ {value: '', disabled: true}, [] ],
            treatment: [ {value: '', disabled: true}, [] ],
            type: [ {value: '', disabled: true}, [] ],
            unit: [ {value: '', disabled: true}, [] ]
        })
      })
    );
  }

  addNewOrderProduct() {
    this.orderProductsFormArr.push(
      this.fb.group({
        id: [ {value: '', disabled: true}, [] ],
        quantity: [ {value: '', disabled: true}, [] ],
        additionalProduct: this.fb.group({
            id: [ {value: '', disabled: true}, [] ],
            name: [ {value: '', disabled: true}, [] ],
            detail: [ {value: '', disabled: true}, [] ],
            composition: [ {value: '', disabled: true}, [] ],
            presentation: [ {value: '', disabled: true}, [] ],
            laboratory: [ {value: '', disabled: true}, [] ],
            saleprice: [ {value: '', disabled: true}, [] ],
            costprice: [ {value: '', disabled: true}, [] ],
            isActive: [ {value: '', disabled: true}, [] ]
        })
      })
    );
  }

  addNewOrderFormulasMedicine() {
    this.orderMedicinesFormulasFormArr.push(
      this.fb.group({
        name: [''],
        unit: [''],
        value: [''],
      })
    )
  }



  setEnabledForm(){
    this.isEdit = true;
    this.form.get('shipping').enable();
    this.form.get('order').get('pharmacy').enable();
    this.form.get('order').get('doctor').enable();
    // Validacion de comentarios
    if(this._auth.roleUser[0] == 'ROLE_ADMIN'){
      this.form.get('comments').get('commentOrder').disable();
      this.form.get('comments').get('commentChoiz').enable();
    }
    if(this._auth.roleUser[0] == 'ROLE_PHARMACY_USER'){
      this.form.get('comments').get('commentOrder').enable();
      this.form.get('comments').get('commentChoiz').disable();
    }

  }
  setDisabledForm(){
    this.isEdit = false;
    this.form.disable();
    // Validacion de comentarios
    if(this._auth.isAdmin()){
      this.form.get('comments').get('commentOrder').disable();
      this.form.get('comments').get('commentChoiz').enable();
    }
    if(this._auth.isPharma()){
      this.form.get('comments').get('commentOrder').enable();
      this.form.get('comments').get('commentChoiz').disable();
    }
  }

  setSaveForm(){
    this.form.enable();
    this._orders.updateOrder(this.form.value, this.selectedOrder()).subscribe(resp => {
      if(resp){
        this._cms.showMessage({ type: 'success', message: 'Actualizado correctamente', title: 'Actualizado', whatsapp: false, autoClose: true });
        this.form.disable()
      }
    })
  }


  async getDataAux(){
   //this._auxiliares.getAll()
  }

  getOptions(card) {
    try{
      card.groups.forEach(group => {
            group.rowCol.forEach(rowcol => {
                rowcol.controls.forEach(control => {
                  if(control.type == 'DROPDOWN'){
                    switch(control.name){
                      case 'birthSex':
                        control.options = this._auxiliares.sexs();
                        break;
                      case 'selfGender':
                        control.options = this._auxiliares.genders();
                        break;
                      case 'pharmacy':
                        if(this._auth.isAdmin()){
                          control.options = this._auxiliares.pharmacies();
                        }
                        break;
                      case 'doctor':
                        if(this._auth.isAdmin()){
                          control.options = this._auxiliares.doctors();
                        }
                        break;
                    }
                  }
              })
            })
        })
      } catch(e){
        console.error('ERROR', e)
      }
  }

  async setDataFormOrder(){
        if(this.selectedOrder() ){
          this.form.get('user').patchValue({
            name: this.selectedOrder()['client'].name,
            lastName: this.selectedOrder()['client'].lastName,
            dni: this.selectedOrder()['client'].dni,
            telephone: this.selectedOrder()['client'].telephone,
            birthSex: '',
            selfGender: '',
            birthDay: '',
            email: this.selectedOrder()['client'].email,
          })
          this.form.get('order').patchValue({
            comboId: this.selectedOrder()['comboId'],
            comboName: this.comboName != null ? this.comboName : this.selectedOrder()['comboId'],
            orderMedicine: this.selectedOrder()['orderMedicines'],
            orderProducts: this.selectedOrder()['orderProducts'],
            frequency: this.frequency != null ? this.frequency : this.selectedOrder()['frequency'],
            medicineQuantity: this.selectedOrder()['medicineQuantity'],
            healthInsuranceCoverage: this.selectedOrder()['healthInsuranceCoverage'],
            medicinePrice: this.selectedOrder()['medicinePrice'],
            salePrice: this.salePrice != null ? this.salePrice : this.selectedOrder()['salePrice'],
            costPrice: this.costPrice != null ? this.costPrice : this.selectedOrder()['costPrice'],
            doctorName: this.selectedOrder()['doctor'].name,
            doctorLastName: this.selectedOrder()['doctor'].lastName,
            tuition: this.selectedOrder()['doctor'].tuitionNumber,
            doctor: this.selectedOrder()['doctor'].id,
            priceTotal: '',
            discount: '',
            paymentLink: this.selectedOrder()['paymentLink'],
            pharmacy: this.selectedOrder()['pharmacy'].id,
            recurrent: this.selectedOrder()['recurrent'],
            treatment: this.selectedOrder()['treatment'],
            created: this.selectedOrder()['created']
          })
          this.form.get('shipping').patchValue({
            additionalInformation: this.selectedOrder()['additionalInformation'],
            street: this.selectedOrder()['street'],
            streetNumber: this.selectedOrder()['streetNumber'],
            apartment: this.selectedOrder()['apartment'],
            apartmentNumber: this.selectedOrder()['apartmentNumber'],
            extraInfo: this.selectedOrder()['extraInfo'],
            province: this.selectedOrder()['province'],
            city: this.selectedOrder()['city'],
            colony: this.selectedOrder()['colony'],
            zipCode: this.selectedOrder()['zipCode'],
            trackingUrl: this.selectedOrder()['trackingUrl'],
            deliveryDate: this.selectedOrder()['deliveryDate'],
          })
          this.form.get('comments').patchValue({
            commentOrder: this.selectedOrder()['commentOrder'],
            commentChoiz: this.selectedOrder()['commentChoiz'],
          })
          // Validacion de comentarios
          if(this._auth.isAdmin()){
            this.form.get('comments').get('commentOrder').disable();
            this.form.get('comments').get('commentChoiz').enable();
          }
          if(this._auth.isPharma()){
            this.form.get('comments').get('commentOrder').enable();
            this.form.get('comments').get('commentChoiz').disable();
          }
          this.salePrice = this.selectedOrder()['orderMedicines'][0].medicine.price;
          this.costPrice = this.selectedOrder()['orderMedicines'][0].medicine.costPrice;
          setTimeout(() => {
            this.loadedData = true;
          }, 1500)

        }
  }

  setDataToArr(){
    if(this.selectedOrder().orderMedicines.length > 0){
      this.selectedOrder().orderMedicines.map(m => {
        this.addNewOrderMedicine();
        if(this.selectedOrder().treatment.id != 1 ){
          this.getComboName(this.selectedOrder()['comboId']);
        }
      })
      if(this.selectedOrder().orderProducts && this.selectedOrder().orderProducts.length > 0){
        this.selectedOrder().orderProducts.map(m => {
          this.addNewOrderProduct();
        })
      }
    }
  }

  getComboName(id){
    this._orders.getComboName(id).subscribe(cn => {
      this.form.get('order').patchValue({ comboName: cn.title })
      this.form.get('order').patchValue({ frequency: cn.frequency })
      this.form.get('order').patchValue({ salePrice: cn.salePrice })
      this.form.get('order').patchValue({ costPrice: cn.costPrice })
      this.salePrice = cn.salePrice;
      this.costPrice = cn.costPrice;
    })
  }

  getImageFile(fileName:string){
    const chatEndpoint = environment.apiUrl;
		const chatFile = "resource";

		const headers = new HttpHeaders({
			'file-name': fileName,
			'bucket-folder-name': 'my-account/customer'
		})

		return this.http.get(`${chatEndpoint}/${chatFile}`, {
			headers,
			responseType: 'blob',
			context: xImageMyAccount()
		}).pipe(
			image => { return image }
		)
  }

  getProspectId(){
    const id = this.selectedOrder().client.id;
    if(this._auth.roleUser[0] == 'ROLE_ADMIN'){
      this._clients.getProspectId(id).subscribe(
          (next) => {
            this.form.get('user').get('prospectId').patchValue(next['prospect_id']);
            this.prospectId = next['prospect_id'];
          },
          (err) => {

          }
      )
    }
  }


}
